<!--
 * @Author: your name
 * @Date: 2022-03-16 10:06:35
 * @LastEditTime: 2022-03-22 19:10:51
 * @LastEditors: shmily
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter1\Lesson7\30_PinyinSummary.vue
-->
<template>
  <div class="summary-container">
    <SummaryPage :lessonNo="13" />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
}
</style>
