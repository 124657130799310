<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-77.wav"),
      },
      timeStampList: [11.5, 14.6, 17.5, 24.0, 26.0, 28.6],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling:
              "Nǐ zuótiān qù nǎr le？",
            chineseWords: "你昨天去哪儿了？ ",
          },
          {
            id: 2,
            spelling:
              "Wǒ zuótiān qù gōngyuán le. ",
            chineseWords: "我昨天去公园了。",
          },
          {
            id: 3,
            spelling:
              "Wǒ qù gōngyuán qímǎ le,nǐ ne？",
            chineseWords: "我去公园骑马了，你呢？",
          },
          {
            id: 4,
            spelling: "Wǒ méi qù gōngyuán,",
            chineseWords: "我没去公园，",
          },
          {
            id: 5,
            spelling:
              "wǒ hé bàba māma zài jiā.",
            chineseWords: "我和爸爸妈妈在家。",
          },
          {
            id: 6,
            spelling:
              "Wǒmen zài yìqǐ kàn diànshì.",
            chineseWords: "我们在一起看电视。",
          }
        ],
      },
    };
  },
};
</script>
